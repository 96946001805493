
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            
            const doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NewPhaseGroup_PhaseGroup"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PhaseGroup"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"NewPhaseGroup_Save"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PhaseGroupInput"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phaseGroupSave"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NewPhaseGroup_PhaseGroup"},"directives":[]}]}}]}}],"loc":{"start":0,"end":248}};
            doc.loc.source = {"body":"\n          fragment NewPhaseGroup_PhaseGroup on PhaseGroup {\n  id\n  createdAt\n  updatedAt\n  position\n  name\n}\n\nmutation NewPhaseGroup_Save($input: PhaseGroupInput!) {\n  phaseGroupSave(input: $input) {\n    ...NewPhaseGroup_PhaseGroup\n  }\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  

              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const NewPhaseGroup_PhaseGroup = oneQuery(doc, "NewPhaseGroup_PhaseGroup");
                
                  export const NewPhaseGroup_Save = oneQuery(doc, "NewPhaseGroup_Save");
                
